const getSites = async () => {
    let result = null
    try {
        result = await window.axios.get('/v1/sites/portfolio')
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const getSensorsForSite = async (siteId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/Sensor/site/${siteId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const getMonitorsForSite = async (siteId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/monitor/site/${siteId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};
const getEquipmentForSite = async (siteId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/equipment/site/${siteId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const getSensorRegistersForMonitor = async (monitorId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/register/sensorregister/monitor/${monitorId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const getSensorUnitOfMeasureByRegisterId = async (monitorId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/sensorunitofmeasure/register/${monitorId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const getSensorTypes = async (sensorUnitOfMeasureId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/sensortype/sensorunitofmeasureid/${sensorUnitOfMeasureId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const getSensor = async (siteId, monitorId, registerId, sensorId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/Sensor/update?siteId=${siteId}&monitorId=${monitorId}&registerId=${registerId}&sensorId=${sensorId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const createSensor = async (sensor) => {
    try {
        await window.axios.post('/v1/Sensor/create', sensor)
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const updateSensor = async (sensor) => {
    try {
        console.log(sensor)
        await window.axios.put('/v1/Sensor/update', sensor)
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const deleteSensor = async (sensor) => {
    try {
        console.log(sensor)
        let config = {data: sensor}
        await window.axios.delete('/v1/Sensor/delete', config)
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

export default {
    getSites,
    getSensorsForSite,
    getMonitorsForSite,
    getEquipmentForSite,
    getSensorRegistersForMonitor,
    getSensorUnitOfMeasureByRegisterId,
    getSensorTypes,
    getSensor,
    createSensor,
    deleteSensor,
    updateSensor
}